
var menuSelect3 = {
  selectButton: document.querySelector(".header__select p"),
  allOption: document.querySelectorAll(".select-item"),
  nameOfEntry: document.querySelector(".menu").getAttribute("data-entry"),

  _initialize: function () {
      this.selectedElement();
  },

  selectedElement: function(){
    var self = this;

    this.allOption.forEach( function() {

      // console.log(self.nameOfEntry);

      if(self.nameOfEntry === "Fiszki językowe"){
        self.selectButton.textContent = self.allOption[0].dataset.title;

      }else if(self.nameOfEntry === "Fiszki matematyczne"){

        self.selectButton.textContent = self.allOption[1].dataset.title;

      }else if(self.nameOfEntry === "Tapety językowe"){
        self.selectButton.textContent = self.allOption[0].dataset.title;

      }else if(self.nameOfEntry === "mathematics"){
        self.selectButton.textContent = self.allOption[1].dataset.title;
      }
    });
  }
};

$(document).ready(function () {
  menuSelect3._initialize();
});

