var newsletter= {
    email: '',

    init: function() {
        this.showPopup();
        this.events();
    },

    showPopup: function() {
        $('.subscribe').click(function ( e ) {
            e.preventDefault();
            popup = $('.newsletter-popup');
            this.email = $('.email-sub').val();
            popup.css('display', 'block');
            $('.newsletter-popup .email-input').val(this.email);
        });
    },

    events: function() {
        $('.newsletter-popup').click(function ( e ) {
            if (e.target.classList.contains('newsletter-popup')) {
                $('.newsletter-popup').css('display', 'none');
            }
        });
    }
};

$(document).ready(function () {
    newsletter.init();
});
