
var menuSelect2 = {
  selectOption: document.querySelectorAll("header .header__option"),
  selectButton: document.querySelector("header .header__select"),

  _initialize: function () {
    this.chooseElement();
  },


  chooseElement: function(){
    var self = this;

    this.selectOption.forEach(function (element) {
      element.addEventListener("click", function(){
        selectedOption = element.innerText;
        self.selectButton.innerHTML = element.innerText;
        self.selectButton.classList.add('select-options');
      });
    }); 
  }
};

$(document).ready(function () {
  menuSelect2._initialize();
});
