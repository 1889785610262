var sticky = {
  _init: function() {
    this.handleSticky();
  },

  handleSticky: function() {
    if( $('.sticked').length === 0 ) {
      return;
    }
    var navY = $('.sticked').offset().top;
    var stickyNav = function(){
    var scrollY = $(window).scrollTop();

    if (scrollY > navY) {
      $('.sticked').addClass('sticky');
    } else {
      $('.sticked').removeClass('sticky');
    }
  };

    stickyNav();

    $(window).scroll(function() {
      stickyNav();
    });
  }
};

$(document).ready(function () {
  sticky._init();
});
