var settings = {

  init: function () {
    this.changePasswordActivate(); 
  },

  changePasswordActivate: function () {
    $(".changePasswordBtn").on("click", function(e) {
      var popup = $('.settings-box');
      popup.css('display', 'block');
      e.preventDefault();
    });
    $(".settings-box").on("click", function(e) {
      if (e.target === document.querySelector('.settings-box') || e.target === document.querySelector('.signin__container')) {
        var popup = $('.settings-box');
        popup.css('display', 'none');
      }
    });
  }

};

$(document).ready(function () {
  settings.init();
});
