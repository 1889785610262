var wallpapers = {
  search: {
    category: null,
    level: null
  },

  filterEdu: function() {
    var edus = document.querySelectorAll(".main__img-box");
    // mam wrażenie że wystarczy tu jakaś prosta klasa :)
    if (search.category && search.level) {
      edus.forEach(function(e) {
        e.style.display = search.category == e.dataset.eduCategory && search.level == e.dataset.eduLevel ? "flex" : "none";
      });
    } else if (search.category) {
      edus.forEach(function(e) {
        e.style.display = search.category == e.dataset.eduCategory ? "flex" : "none";
      });
    } else if (search.level) {
      edus.forEach(function(e) {
        e.style.display = search.level == e.dataset.eduLevel ? "flex" : "none";
      });
    } else {
      edus.forEach(function(e) {
        e.style.display = "flex";
    });
    }
  },

  // hide categories that are not used
  hideUnnecessaryCat: function() {
    var icons = document.querySelectorAll(".icons__icon-box");
    var edus = document.querySelectorAll(".main__img-box");
    icons.forEach(function(icon) {
      edus.forEach(function(e) {
        if (e.dataset.eduCategory == icon.dataset.category) {
          icon.style.visibility = "visible";
          return;
        } 
      });
  });

  icons.forEach(function(e) {
      if (e.style.visibility != "visible") {
        e.remove();
      }
    });
  },

  // Giving indexes to wallpapers by categories (001, 002 etc)
  indexByCategories: function() {
    var indexDOM;
    var icons = document.querySelectorAll(".icons__icon-box");
    var edus = document.querySelectorAll(".main__img-box");
    icons.forEach(function(icon) {
      var index = 1;
      edus.forEach(function(edu) {
          if (edu.dataset.eduCategory == icon.dataset.category) {
            indexDOM = edu.querySelector(".img-box__index");
            if (index < 10) {
                indexDOM.textContent = "00" + index;
            } else if (index > 9) {
                indexDOM.textContent = "0" + index;
            } else if (index > 99) {
                indexDOM.textContent = index;
            }
            index++;
          }
      });
    });
  }
};

$(document).ready(function() {
  if (window.location.pathname.includes("fiszki-jezykowe-tapety")) {
    popup.signed();
  }
});
