var posts = {
  loadMoreButton: "#more-button",
  cardsWrapper: $('.main__img-box'),
  sectionName: '',
  pageNumber: 1,
  pageLimit: 9,
  categories: '',
  selectedLevelOptions: document.querySelector(".level-choose"),
  selectedCategoryOptions: document.querySelector(".category-choose"),
  pageTotal: '',
  resetButton: document.querySelector(".reset-filter-button"),

  _init: function() {
    if( this.cardsWrapper.length === 0 ) {
      return;
    }
    this.sectionName = $(this.cardsWrapper).data('section');
    this.loadPosts();
    this.registerEvents();
    this.reset();
  },

  registerEvents: function() {
    this.cardsWrapper.on('click', this.loadMoreButton, this.loadNextPage.bind(this));
  },

  loadNextPage: function() {
    this.removeLoadMoreButton();
    this.pageNumber++;
    this.loadPosts();
  },

  removeLoadMoreButton: function() {
    $(this.loadMoreButton).parent().remove();
  },

  clearWrapper: function() {
    this.cardsWrapper.empty();
    this.pageNumber = 1;
  },

  reset: function(){
    self = this;
    this.resetButton.addEventListener("click", function(){
      var uri = window.location.toString();
      if (uri.indexOf("?") > 0) {
          var clean_uri = uri.substring(0, uri.indexOf("?"));
          window.history.replaceState({}, document.title, clean_uri);
      }
      self.selectedCategoryOptions.dataset.categoryId = "";
      self.selectedLevelOptions.dataset.categoryId = "";
      self.categories = null;
      self.clearWrapper();
      self.loadPosts();
      $(".level-choose").text("");
      $(".category-choose").text("");
    });
  },

  onCategoryChange: function(){

    var temoraryArray = [];

    temoraryArray.push(this.selectedLevelOptions.getAttribute('data-category-id'));
    temoraryArray.push(this.selectedCategoryOptions.getAttribute('data-category-id'));

    this.categories = temoraryArray.toString();

    this.clearWrapper();
    this.loadPosts();

  },

  loadPosts: function() {
    var offset = this.pageLimit * (this.pageNumber - 1 );
    var loader = $('body').find('.lds-ripple');
    $(loader).addClass('active');
    if(offset == this.pageNumber){
      $("#more-button").css("display", "none");
    }
    $.get('ajax/cards-load', {
      type: this.sectionName,
      offset: offset,
      limit: this.pageLimit,
      categories: this.categories,
    }, function (data) {
      var postOutput = $('body').find('.main__img-box');
      postOutput.append(data);
    }).done(function() {
      $(loader).removeClass('active');
      this.pageTotal = $( ".main__img-box" ).find( ".main__img-wrapper").length;
    });
  },
};

$(document).ready(function() {
  posts._init();
});
