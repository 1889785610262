var languages = {
    init: function () {
        this.showLangs();
    },

    showLangs: function () {
        var lang = $('.language');
        var isBlock = false;
        var availableLangs = $('.language li');
        $('.language .active a').attr('href', '#');
        $('.language .active').click(function () {
            if (!isBlock) {
                availableLangs.each(function ( el ) {
                    $(this).css('display', 'flex');
                });
                isBlock = !isBlock;
            } else {
                availableLangs.each(function ( el ) {
                    $(this).css('display', 'none');
                });
                $(this).css('display', 'flex');
                isBlock = !isBlock;
            }
        });
    }
};

languages.init();
