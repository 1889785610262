var popupCards = {

  exitButton: '.popup-wrapper .popup-cotent .exit-cross',

  _init: function () {
    this.cardsPopup();
  },

  cardsPopup: function () {

    var cardsLangWindowWidth = window.screen.width; 
    var cardsElement = document.querySelector(".cards-popup-wrapper");

    if(cardsElement === null) {
      return true;
    }
    if(cardsLangWindowWidth <= 768){
      cardsElement.style.display = "flex";
    }
    document.body.addEventListener("click", function(){
      cardsElement.style.display = "none";
    });
  }
};

$(document).ready(function () {
  popupCards._init(); 
});