
var menuSelect = {

  _initialize: function () {
    this.selectAnimation();
  },

  selectAnimation: function () {

    var selectButton = document.querySelector(".header__select p");
    var selectButtonLogIn = document.querySelector(".logIn-select");

    var selectContent = document.querySelector(".select-list");
    var selectContenLogIN = document.querySelector(".logIn-list");

    selectButton.addEventListener("click", function(){
      selectContent.classList.toggle("visibleSelect");
      this.classList.toggle("visibleSelectBorderRadius");
    });

    if(selectButtonLogIn === null) {
      return true;
    }

    $(document).click(function ( e ) {
      selectContenLogIN.classList.remove("visibleSelect");
      selectButtonLogIn.classList.remove("visibleSelectBorderRadius");
    });

    selectButtonLogIn.addEventListener("click", function(e){
      e.stopPropagation();
      selectContenLogIN.classList.toggle("visibleSelect");
      this.classList.toggle("visibleSelectBorderRadius");
    });
  },
};

$(document).ready(function () {
  menuSelect._initialize();
});
