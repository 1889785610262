var CONTROLLER_URL = 'actions/flience/limit/';
var GET_LIMIT_URL = CONTROLLER_URL + 'get-limit';
var IS_LOGGED_URL = CONTROLLER_URL + 'is-logged-in';
var GET_MAX_LIMIT_URL = CONTROLLER_URL + 'max-limit';
var GET_ACCOUNT_TYPE = CONTROLLER_URL + 'get-account-type';

var usersLimits = {
	limit: 0,
	maxLimit: 0,
	loaderBox: $('.dow-loader-box'),
	loader: $('.dow-loader-box .lds-ripple'),
	header: document.querySelector('header'),
	alert: $('.alert'),
	limitAlert: $('.limitAlert'),
	amountAlert: $('.amountAlert'),

	isLogged: false,
	isPremium: false,
	isLangCardflies: false,
	isMathCardflies: false,
	isLangScreenflies: false,

	init: function () {
		this.checkPremium();
		this.checkType();
		this.getLimit();
		this.getMaximumLimit();
		if (!this.isLangCardflies && !this.isMathCardflies && !this.isLangScreenflies) {
			return;
		}

		this.isLoggedIn();
		this.sendForm();
		this.events();
	},

	checkType: function () {
		this.isLangCardflies = this.header.dataset.entry === 'Fiszki językowe';
		this.isMathCardflies = this.header.dataset.entry === 'Fiszki matematyczne';
		this.isLangScreenflies = this.header.dataset.entry === 'Tapety językowe';
	},

	checkPremium: function () {
		var self = this;
		$.ajax({
			type: 'get',
			url: GET_ACCOUNT_TYPE,
			success: function (response) {
				self.isPremium = response == 0 ? false : true;
				self.updateLimitDisplay();
			}
		});
	},

	updateLimitDisplay: function () {
		if (this.isPremium) {
			$('.limits-show.premium').addClass('displayFlex');
			$('.limits-show.free').removeClass('displayFlex');
		} else {
			$('.limits-show.free').addClass('displayFlex');
			$('.limits-show.premium').removeClass('displayFlex');
			$('.limits-have').text(this.limit);
			$('.limits-max').text(this.maxLimit);
		}
	},

	getLimit: function () {
		var self = this;
		$.ajax({
			type: "get",
			url: GET_LIMIT_URL,
			success: function (response) {
				var limit = response && parseInt(response) > 0 ? parseInt(response) : 0;
				self.limit = limit;
				self.updateLimitDisplay();
			},
			error: function (err) {
				self.limit = 0;
				console.log(err);
				self.removeLoaders();
			}
		});
	},

	isLoggedIn: function () {
		var self = this;
		$.ajax({
			type: "get",
			url: IS_LOGGED_URL,
			success: function (response) {
				self.isLogged = !!parseInt(response);
			},
			error: function (err) {
				self.isLogged = false;
			}
		});
	},

	events: function () {
		this.alert.click(function (e) {
			if (e.target === this) {
				$(this).removeClass('visAndOpTrue');
			}
		});
	},

	sendForm: function () {
		var self = this;
		$(".download-form-submit").on("click", function (e) {
			e.preventDefault();
			if (!self.isLogged) {
				self.alert.addClass('visAndOpTrue');
				return;
			}
			$(".amountOfWallpapers").val(flashcards.flashcards.length);
			var serialized = $(".download-form").serialize();
			self.loader.addClass('active');
			self.loaderBox.addClass('displayBlock');


			self.getLimit();

			if (self.isPremium) {
				console.log('XD');
				self.download(1);
				return;
			}
			console.log('free');

			if (self.limit < 1 || usersLimits.limit - flashcards.flashcards.length < 0) {
				self.removeLoaders();
				return;
			}

			$.ajax({
				type: "post",
				url: CONTROLLER_URL + 'add-download',
				dataType: 'json',
				data: serialized,
				success: function (response) {
					if (response > 0) {
						console.log(response);
						self.download(response);
						self.getLimit();
						this.loaderBox.removeClass('displayBlock');
					} else {
						self.removeLoaders.bind(self);
					}
				}, error: self.removeLoaders.bind(self)
			});

		});
	},

	removeLoaders: function () {
		var self = this;
		this.loaderBox.removeClass('displayBlock');
		this.limitAlert.addClass('visAndOpTrue');
		$(".limitAlert-text").text("Too much!");
		setTimeout(function () {
			$('.amountAlert-counter').text(usersLimits.limit);
			self.amountAlert.removeClass('visAndOpTrue');
		}, 1000);
	},

	download: function (response) {
		if (this.isMathCardflies) {
			flashcards.makePdf();
		}
		if (this.isLangCardflies) {
			flashcards.makePdf();
		}
		if (this.isLangScreenflies) {
			popup.download();
		}
		$(".header__download--count").text("0");
		$(".active-flashcard").removeClass("active-flashcard");
		this.limit = response;
	},

	showAlert: function () {
		$(".alert").addClass('visAndOpTrue');
		self.loaderBox.removeClass('displayBlock');
	},

	getMaximumLimit: function () {
		var self = this;
		$.ajax({
			type: "get",
			url: GET_MAX_LIMIT_URL,
			success: function (response) {
				self.maxLimit = response;
				self.updateLimitDisplay();
			}
		});
	}
};

$(document).ready(function () {
	usersLimits.init();
});
