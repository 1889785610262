var popup = {
  popupDOM: document.querySelector(".popup") ? document.querySelector(".popup") : null,
  isSelectOpen: false,
  popup: null,
  indexGlobal: null,
  selectBox: null,
  downloadInfo: {
    lang: null,
    res: { 
      width: window.screen.width,
      height: window.screen.height
    },
    wallpaper: null
  },

  changePopup: function(index) {
    var edus = document.querySelectorAll(".main__img-box");
    var popupCards = document.querySelectorAll(".popup__content");
    popupCards.forEach(function(e) {
        e.style.display = "none";
    });

    popupCards[index].style.display = "flex";
    
    popup.popup = popupCards[index];
    popup.selectBox = popup.popup.querySelector(".screenflies-select-list");
    popup.indexGlobal = index;
    popup.isSelectOpen = false;
    popup.chooseFirstSelect();
    popup.popup.querySelector(".lang__item").classList.add("lang__item--active");
  },

  chooseFirstSelect: function() {
    var inputs = popup.popup.querySelectorAll(".input__option");
    var langs = popup.popup.querySelectorAll(".lang__item");
    popup.downloadInfo.lang = 0;

    var firstSelect = null;
    firstSelect = window.screen.width + 'x' + window.screen.height + ' (Your Resolution)';
    $('.YourRes').text(window.screen.width + 'x' + window.screen.height);

    popup.popup.querySelector(".chosen-res").textContent = firstSelect;
  },

  signed: function() {

    popup.popupDOM.addEventListener("click", function(e) {
      if (e.target.classList.contains("popup")) {
        e.target.style.display = "none";
      }
    });

    document.querySelector(".popup__close").addEventListener("click", function() {
      popup.popupDOM.style.display = "none";
    });
    
    document.querySelector(".main").addEventListener("click", function(e) {
      if (e.target.classList.contains("download-screenflies-button")) {
          var imgs = document.querySelectorAll(".download-screenflies-button");
          var index = null;
          for (var i = 0; i < imgs.length; i++) {
            if (imgs[i] === e.target) {
              index = i;
              break;
            }
          }
          popup.changePopup(index);
          popup.popupDOM.style.display = "block";
      }
    });

    document.querySelector(".popup__wrapper").addEventListener("click", function(e) {
      if (e.target.classList.contains("select-item")) {
        popup.popup.querySelector(".chosen-res").textContent = e.target.textContent;
        var resolution = e.target.textContent.split("x");
        popup.downloadInfo.res.width = resolution[0];
        popup.downloadInfo.res.height = resolution[1];
        popup.selectBox.classList.remove("visibleMenu");
        popup.isSelectOpen = false;
      } else if (e.target.classList.contains("lang__item")) {
        var langs = popup.popup.querySelectorAll(".lang__item");
        for (var i = 0; i < langs.length; i++) {
            if (langs[i].textContent == e.target.textContent) {
                popup.downloadInfo.lang = i;
                break;
            }
        }
          Array.from(langs).forEach(function(el) {
              el.classList.remove("lang__item--active");
          });
          e.target.classList.add("lang__item--active");
      } else if (e.target.classList.contains("download")) {
          
      } else if (e.target.classList.contains("screenflies-select")) {
        popup.popup.querySelector(".screenflies-select-list").classList.toggle('visibleMenu');
      }
    });
  },

  download: function () {
    var assetId = popup.popup.querySelectorAll(".img-to-download")[popup.downloadInfo.lang].dataset.assetId;
    var a;
    self = this;
    $.ajax({
      method: 'GET',
      data: {
        width: popup.downloadInfo.res.width,
        height: popup.downloadInfo.res.height,
        assetId: assetId
      },
      url: "ajax/wallpaper-transform",
      headers: {
        'Access-Control-Allow-Origin': '*'
      },
      success: function ( data ) {
        var img = new Image();
        img.crossOrigin = 'Anonymous';
        img.src = data;
        img.onload = function () {
          var canvas = document.createElement('canvas');
          canvas.width = popup.downloadInfo.res.width;
          canvas.height = popup.downloadInfo.res.height;
          canvas.getContext('2d').drawImage(img, 0, 0, popup.downloadInfo.res.width, popup.downloadInfo.res.height);
          a = document.createElement('a');
          a.href = canvas.toDataURL('image/jpeg');
          a.download = "flience_wallpaper.jpg";
          a.click();
          $('.dow-loader-box').removeClass('displayBlock');
        }
      }
    });


    // $.get("ajax/wallpaper-transform", {
    //   width: popup.downloadInfo.res.width,
    //   height: popup.downloadInfo.res.height,
    //   assetId: assetId
    // }, function(data) {
    //   var data = self.createElementFromHTML(data);
    //   data.crossOrigin = 'Anonymous';
    //   data.onload = function () {
    //     var canvas = document.createElement('canvas');
    //     canvas.width = popup.downloadInfo.res.width;
    //     canvas.height = popup.downloadInfo.res.height;
    //     canvas.getContext('2d').drawImage(data, 0, 0, popup.downloadInfo.res.width, popup.downloadInfo.res.height);
    //     a = document.createElement('a');
    //     a.href = canvas.toDataURL();
    //     a.download = "flience_wallpaper.jpg";
    //     a.click();
    //     // document.body.appendChild(a);
    //     // a.href = data.replace(/(\r\n|\n|\r)/gm,"");
    //     // a.setAttribute('target', '_blank');
    //     // a.setAttribute( 'download', 'flience_wallpaper' );
    //     // a.click();
    //     // document.body.removeChild(a);
    //     $('.dow-loader-box').removeClass('displayBlock');
    //   }

    // });
  },

  createElementFromHTML: function(htmlString) {
    var div = document.createElement('div');
    div.innerHTML = htmlString.trim();

    return div.firstChild;
  },

  notSigned: function() {
    document.querySelector(".main").addEventListener("click", function(e) {
      if (e.target.classList.contains("main__img")) {
        var alert = document.querySelector(".alert");

        alert.style.opacity = "1";
        alert.style.visibility = "visible";

        setTimeout(function() {
          alert.style.opacity = "0";
          alert.style.visibility = "hidden";
        }, 2000);
      }
    });

    document.addEventListener("click", function(e) {
      if (!e.target.classList.contains("alert") && !e.target.classList.contains("main__img")) {
        var alert = document.querySelector(".alert");
        alert.style.opacity = "0";
        alert.style.visibility = "hidden";
      }
    });
  }
};
