
var hamburgerMenu = {

  _initialize: function () {
    this.collapseMenu();
  },

  collapseMenu: function () {
    var menuButton = document.querySelector('.hamburger-menu');
    var menuContent = document.querySelector('.menu-elements');
    var html = document.documentElement;
    var hamburMenuBackground = document.querySelector('.main-header');
    var hamburgerMenuLogo = document.querySelector('.header-logo-img-white');

    menuButton.addEventListener("click", function(){
      menuContent.classList.toggle('visibleMenu');
      html.classList.toggle("menuOverflow");
      hamburMenuBackground.classList.toggle("menuBackgroundClor");
      hamburgerMenuLogo.classList.toggle("logochange");

      this.classList.toggle("active");

      if (menuButton.classList.contains('visible')) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    });
  },
};

$(document).ready(function () {
  hamburgerMenu._initialize();
});
