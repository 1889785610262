var CONTROLLER_URL = 'actions/flience/limit/';
var GENERATE_PDF_URL = CONTROLLER_URL + 'generate-pdf';

var flashcards = {
    header: document.querySelector('header'),

    flashcards: [],
    isLangCardflies: false,
    isMathCardflies: false,

    _init: function() {
        this.checkType();
        if( !this.isLangCardflies && !this.isMathCardflies ) {
            return;
        }
        this.registerEvents();
    },


    checkType: function() {
        this.isLangCardflies = this.header.dataset.entry === 'Fiszki językowe';
        this.isMathCardflies = this.header.dataset.entry === 'Fiszki matematyczne';
    },

    registerEvents: function() { 
        $( ".main" ).click(function ( e ) {
            if ( e.target.classList.contains( "langs__item" ) ) {
                if ( e.target.classList.contains( "active-flashcard" ) ) {
                    flashcards.flashcards = flashcards.flashcards.filter( function ( el ) {
                        return el.back !== e.target.dataset.back;
                    } );
                } else {
                    var flashcard = {};
                    flashcard.front = e.target.parentElement.previousElementSibling.dataset.front;
                    console.log(e.target);
                    flashcard.back = e.target.dataset.back;

                    flashcards.flashcards.push( flashcard );
                }
                e.target.classList.toggle( "active-flashcard" );
                if ( $( e.target ).hasClass( "active-flashcard" ) ) {
                    e.target.parentElement.classList.add( 'activeCirceblenda' );
                } else {
                    e.target.parentElement.classList.remove( 'activeCirceblenda' );
                    var langs = e.target.parentElement.children;
                    for ( var i = 0; i < langs.length; i++ ) {
                        if ( $( langs[ i ] ).hasClass( "active-flashcard" ) ) {
                            e.target.parentElement.classList.add( 'activeCirceblenda' );
                            break;
                        }
                    }
                }
                document.querySelector( ".header__download--count" ).textContent = flashcards.flashcards.length;
            }
        } ); 
    },
    
    makePdf() {
        $('.dow-loader-box .lds-ripple').addClass('active');
        $('.dow-loader-box').addClass('displayBlock');
        var self = this;
        var type = this.isLangCardflies ? 'lang' : 'math';
        $.ajax({
            url: GENERATE_PDF_URL,
            method: 'get', 
            data: {ids: self.flashcards, type: type},
            xhrFields: {
              responseType: 'blob'
            },
            success: function(blob) {
                flashcards.flashcards = [];
                var link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download="flience" + ".pdf";
                link.click();
                $('.dow-loader-box').removeClass('displayBlock');
            }
        });
        $( ".langs__item" ).removeClass( "active-flashcard" );
        $('.main__img-langs').removeClass('activeCirceblenda');
        $('.header__download--count').text('0');
    }

};

$( document ).ready(function () {
    flashcards._init();
});
