var cookies = {
    notification: document.querySelector('.cookies'),

    init: function() {
        this.hasCookies();
        this.events();
    },

    hasCookies: function() {
        // pozbywamy się wpisanych na sztywno klas i nazw
        var cookiesString = document.cookie;
        if (cookiesString.search('accepted') !== -1) {
            // zróbmy osbługę klasami
            this.notification.classList.add('displayNone');
        }
    },


    events: function() {
        var self = this;
        // pozbywamy się wpisanych na sztywno klas i nazw
        $('.cookies-accept').click(function ( e ) {
            // zróbmy osbługę klasami
            self.notification.classList.add('displayNone');
            cookie.set('accepted', 'true');
        });
    }
};

$(document).ready(function() {
    cookies.init();
});
