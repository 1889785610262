var cardBlenda = {
  allShortcutsMathButton: document.querySelectorAll(".maths__item"),
  activeBlenda: 'activeCirceblenda',
  activeMath: 'activeMathCircle',

  _initialize: function () {
    this.blendaOnCards();
  },

  blendaOnCards: function () {
    var self = this;
    this.allShortcutsMathButton.forEach(function (oneShortcut){
      oneShortcut.addEventListener("click", function(){
        this.classList.toggle(self.activeMath);
        if($(this.parentElement).find('.activeMathCircle').length) {
          this.parentElement.classList.add(self.activeBlenda);
        }else { 
          this.parentElement.classList.remove(self.activeBlenda);
        }
      });
    });
  }
};

$(document).ready(function () {
  cardBlenda._initialize();
});
