var screenfliesPopup = {
  
  _initialize: function () {
    this.popupScreenflies();
  },

  chooseFirstSelect: function() {
    var inputs = popup.popup.querySelectorAll(".select-item");
    popup.downloadInfo.lang = 0;
    var resolutions = Array.from(inputs).map(function(e) {
    var splited = e.textContent.split("x");
      return {
        width: splited[0],
        height: splited[1]
      };
    });

    var firstSelect = null;

    for (var i = 0; i < resolutions.length; i++) {
      if (resolutions[i].width == window.screen.width && resolutions[i].height == window.screen.height) {
        firstSelect = inputs[i].textContent;
        break;
      }
    }

    if (!firstSelect) firstSelect = inputs[0].textContent;
    firstSelect = firstSelect.split("x");
    popup.downloadInfo.res.width = firstSelect[0];
    popup.downloadInfo.res.height = firstSelect[1];
    popup.popup.querySelector(".chosen-res").textContent = popup.downloadInfo.res.width + "x" + popup.downloadInfo.res.height;
  },

  popupScreenflies: function () {
    var self = this;
    var screenfliesLanguagePopup = document.querySelectorAll(".popup__selection-lang");
    var screenfliesSelect = document.querySelector(".screenflies-select");
    var screenfliesSelectList = document.querySelector(".screenflies-select-list");
    var popupScreenfliesShow = document.querySelectorAll(".download-screenflies-button");
    var screenfliesPopup = document.querySelector(".popup");
    var screenfliesPopups = document.querySelectorAll(".popup__content");

    screenfliesLanguagePopup.forEach(function (oneLanguageButton) {
      oneLanguageButton.addEventListener("click", function(){
        this.classList.add('activeMathCircle');
      });
    });

    screenfliesSelect.addEventListener("click", function(){
      screenfliesSelectList.classList.toggle('visibleMenu');
      this.classList.toggle("visibleSelectBorderRadius");
      // html.classList.toggle("menuOverflow");
  });

  popupScreenfliesShow.forEach(function (item, index) {
    item.addEventListener("click", function(){
      screenfliesPopups.forEach(function(e) {
        e.style.display = "none";
      });
      screenfliesPopups[index].style.display = "flex";
      screenfliesPopup.style.display = "flex";
      popup.popup = screenfliesPopups[index];
      popup.selectBox = screenfliesPopups[index].querySelector(".screenflies-select-list");
      screenfliesPopups[index].querySelector(".lang__item").classList.add("lang__item--active");
      self.chooseFirstSelect();
    });
  });
  }
};

$(document).ready(function () {
  // screenfliesPopup._initialize();
});