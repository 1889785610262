
var svgConvert = {
  _initialize: function () {
    this.transformSVGToInline();
  },

  transformSVGToInline: function() {
    $('img.svg').each(function() {
      var $img = $(this);
      var imgID = $img.attr('id');
      var imgClass = $img.attr('class');
      var imgURL = $img.attr('src');
      $.get(imgURL, function(data) {
        var $svg = $(data).find('svg');

        if (typeof imgID !== 'undefined')
        $svg = $svg.attr('id', imgID);

        if (typeof imgClass !== 'undefined')
        $svg = $svg.attr('class', imgClass + ' replaced-svg');

        $svg = $svg.removeAttr('xmlns:a');
        $img.replaceWith($svg);
    }, 'xml');
  });
},
};

$(document).ready(function () {
  svgConvert._initialize();
});