var menu = {
    init: function () {
        this.usernameLengthCheck(13);
    },

    usernameLengthCheck(maxLength) {
        var username = $('.menu-actions .sign-in-text p').text();
        if (username.length > maxLength + 3) {
            username = username.substring(0, maxLength) + '...';
        }
        $('.menu-actions .sign-in-text p').text(username);
    }
};

$(document).ready(function () {
    menu.init();
});
