var registeryCards = {

    freeSubscription: document.querySelector( '.first-month' ),
    feeSubscription: document.querySelector( '.next-months' ),

    _initialize: function () {
        this.cardsRegistery();
        this.nextStep();
    },

    cardsRegistery: function () {

        if ( !this.freeSubscription || this.freeSubscription.length < 0 ) {
            return true;
        }

        var self = this;

        this.freeSubscription.classList.add( 'selected-first-month' );
        this.feeSubscription.classList.remove( 'selected-next-months' );

        this.freeSubscription.addEventListener( "click", function () {
            self.selectFree();
        } );

        this.feeSubscription.addEventListener( "click", function () {
            self.selectFee();
        } );


        $( '.password-first' ).change(this.passwordCheck);
        $( '.password-second' ).change(this.passwordCheck);
    },

    selectFree: function () {
        this.freeSubscription.classList.toggle( 'selected-first-month' );
        // this.feeSubscription.classList.toggle('selected-next-months');
    },

    selectFee: function () {
        // this.freeSubscription.classList.toggle('selected-first-month');
        // this.feeSubscription.classList.toggle('selected-next-months');
    },

    nextStep: function () {
        $( '.step' ).click( function () {
            $( '.signup__subscription' ).css( 'display', 'none' );
            $( '.signup__box' ).css( 'display', 'flex' );
        } );
    },

    passwordCheck: function () {
        var password1 = $( '.password-first' );
        var password2 = $( '.password-second' );
        if ( password1.val() !== password2.val() && password1.val() !== '' && password2.val() !== '') {
            $('.password-error').css('display', 'block');
            $('.signup-btn').attr('disabled', true);

        } else {
            $('.password-error').css('display', 'none');
            $('.signup-btn').attr('disabled', false);

        }
    }
};

$( document ).ready( function () {
    registeryCards._initialize();
} );
