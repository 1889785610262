var premium = {

    modal: '.modal1',

    init: function () { 
        this.registerEvents();
        this.hidePopup();
        this.showPopup();
    },

    registerEvents: function() {
        $('input[type="radio"]').click(function () {
            if ($(this).attr ("value") == "company") {
                $('.person').hide();
                $('.company').show();
            }
            else if ($(this).attr ("value") == "person") {
                $('.company').hide();
                $('.person').show();
            } 
        });
        $('.cardp').click(function(){
            $('.cardp').removeClass('choosen');
            $(this).addClass('choosen');   
        });
        // $('.open-modal').click(function() {
        //     $('.modal1').show();
        // });

    },
    hidePopup: function() {
        $('.close-modal').click(function () {
            $('.modal').css('display', 'none');
        });
    },
    
    showPopup: function() {
        $('.open-modal').click(function () {
            $('.modal1').css('display', 'block');
        });
    },
 
};

$(document).ready(function () {
    premium.init();
});