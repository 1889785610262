var modals = {
    init: function () {
        modals.events();
    },

    events: function () {
        $('.popup-wrapper-limit').click(function () {
            modals.animateFadeOut($(this));
        });

        $('.popup-wrapper-limit .button-close').click(function () {
            modals.animateFadeOut($(this.parentElement.parentElement));
        })

        $('.button-close').click(function (e) {
            e.preventDefault();
            var modal = this.parentElement.parentElement;
            if (!modal.classList.contains('popup-wrapper-limit')) {

                modal.style.display = 'none';
            }
        })
    },

    animateFadeOut: function ( el ) {
        el.removeClass('visAndOpTrue');
    }
}

$(document).ready(function () {
    modals.init();
})
